import axios from '@/plugins/axios';
import i18n from '@/plugins/i18n';

export default {
  async signIn({ commit }, payload) {
    try {
      await axios.get('/sanctum/csrf-cookie');

      const response = await axios.post('/login', payload);

      const { data } = response;

      localStorage.setItem('user', JSON.stringify(data));

      commit('setUser', data);
    } catch (e) {
      const { errors, message } = e.response?.data || {};

      commit('setAuthErrors', errors || []);

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });

      throw e;
    }
  },
  autoSignIn({ commit }) {
    const user = localStorage.getItem('user');

    if (user) {
      commit('setUser', JSON.parse(user));
    }
  },
  logout({ commit }) {
    localStorage.removeItem('user');

    commit('setUser', null);
  },
};
