import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store/index';
import axios from '@/plugins/axios';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      requiresUnauth: true,
    },
  },
  {
    path: '/home',
    name: 'home',
    redirect: { name: 'listLoadOrders' },
    component: () => import('@/views/Home.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/load-order',
    component: () => import(/* webpackChunkName: "load-order" */ '@/views/LoadOrder.vue'),
    children: [
      {
        name: 'editLoadOrder',
        path: 'edit/:bostamp',
        component: () => import(/* webpackChunkName: "load-order" */ '@/components/load-order/Form.vue'),
        meta: {
          requiresAuth: true,
        },
        props: true,
      },
      {
        name: 'createLoadOrder',
        path: 'create',
        component: () => import(/* webpackChunkName: "load-order" */ '@/components/load-order/Form.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'listLoadOrders',
        path: 'list',
        component: () => import(/* webpackChunkName: "load-order" */ '@/components/load-order/List.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'listTransporter',
        path: 'list-transporter',
        component: () => import(/* webpackChunkName: "load-order" */ '@/components/load-order/listings/Transporter.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'listPark',
        path: 'list-park',
        component: () => import(/* webpackChunkName: "load-order" */ '@/components/load-order/listings/Park.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'listParkEntries',
        path: 'list-park-entries',
        component: () => import(/* webpackChunkName: "load-order" */ '@/components/load-order/listings/ParkEntries.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'listParkExits',
        path: 'list-park-exits',
        component: () => import(/* webpackChunkName: "load-order" */ '@/components/load-order/listings/ParkExits.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'listProvider',
        path: 'list-provider',
        component: () => import(/* webpackChunkName: "load-order" */ '@/components/load-order/listings/Provider.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'listAgent',
        path: 'list-agent',
        component: () => import(/* webpackChunkName: "load-order" */ '@/components/load-order/listings/Agent.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'listClient',
        path: 'list-client',
        component: () => import(/* webpackChunkName: "load-order" */ '@/components/load-order/listings/Client.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'ListTeams',
        path: 'list-teams',
        component: () => import(/* webpackChunkName: "load-order" */ '@/components/load-order/listings/Teams.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'listGlobal',
        path: 'list-global',
        component: () => import(/* webpackChunkName: "load-order" */ '@/components/load-order/listings/Global.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'createDeliveryNote',
        path: '/create-delivery-note/:bostamp',
        component: () => import(/* webpackChunkName: "load-order" */ '@/components/forms/DeliveryNote.vue'),
        meta: {
          requiresAuth: true,
        },
        props: true,
      },
      {
        name: 'clientAnalysis',
        path: 'client-analysis',
        component: () => import(/* webpackChunkName: "analysis" */ '@/views/ClientAnalysis.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'certifiedMaterial',
        path: 'certified-material',
        component: () => import(/* webpackChunkName: "load-order" */ '@/components/load-order/listings/CertifiedMaterialList.vue'),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '*',
    component: () => import('@/views/NotFound.vue'),
    name: 'notFound',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, _from, next) => {
  axios.interceptors.response.use((response) => response, async (error) => {
    if (error.response?.status === 404) {
      next({ name: 'notFound' });
    }

    if (error.response?.status === 401) {
      await store.dispatch('auth/logout');
      next({ name: 'login' });
    }

    return Promise.reject(error);
  });

  store.dispatch('auth/autoSignIn');

  if (to.meta.requiresAuth && !store.getters['auth/isAuthenticated']) {
    next({ name: 'login' });
  } else if (to.meta.requiresUnauth && store.getters['auth/isAuthenticated']) {
    next({ name: 'home' });
  } else {
    next();
  }
});

export default router;
