import Vue from 'vue';
import Vuex from 'vuex';

import getters from './getters';
import mutations from './mutations';
import actions from './actions';

import auth from './modules/auth/index';
import clients from './modules/clients/index';
import loadOrders from './modules/load-orders/index';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    auth,
    clients,
    loadOrders,
  },
  state: {
    drawerOpen: null,
    msg: '',
    alertType: '',
    isLoading: false,
    changelogDialog: false,
  },
  getters,
  mutations,
  actions,
});
