import Vue from 'vue';
/* import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing'; */
import VueMeta from 'vue-meta';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import '@mdi/font/css/materialdesignicons.css';
import i18n from './plugins/i18n';
import './plugins/vee-validate';

Vue.config.productionTip = false;

/* Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'wot.sotimber.pt', /^\//],
    }),
  ],
  tracesSampleRate: process.env.VUE_APP_SENTRY_RATE,
}); */

Vue.use(VueMeta);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
