<template>
  <v-app>
    <the-sidebar v-if="isAuthenticated"/>
    <the-topbar v-if="isAuthenticated"/>
    <v-main>
      <v-container fluid>
        <router-view/>
      </v-container>
      <wot-fab-scroll/>
    </v-main>
    <wot-snackbar/>
    <wot-loading v-if="isLoading"/>
  </v-app>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  components: {
    TheSidebar: () => import(/* webpackChunkName: "navigation" */ './components/layout/TheSideBar.vue'),
    TheTopbar: () => import(/* webpackChunkName: "navigation" */ './components/layout/TheTopBar.vue'),
    WotSnackbar: () => import(/* webpackChunkName: "wot" */ './components/wot/WotSnackbar.vue'),
    WotLoading: () => import(/* webpackChunkName: "wot" */ './components/wot/WotLoading.vue'),
    WotFabScroll: () => import(/* webpackChunkName: "wot" */ './components/wot/WotFabScroll.vue'),
  },
  methods: {
    ...mapMutations(['setMsg']),
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated']),
    ...mapGetters(['isLoading', 'isDrawerOpen']),
    message: {
      get() {
        return this.msg;
      },
      set(value) {
        this.setMsg(value);
      },
    },
  },
};
</script>

<style>
@font-face {
  font-family: HKGrotesk;
  font-weight: 300;
  src: url('~@/assets/fonts/hk-grotesk.light.ttf') format('truetype');
}
@font-face {
  font-family: HKGrotesk;
  font-weight: 400;
  src: url('~@/assets/fonts/hk-grotesk.regular.ttf') format('truetype');
}
@font-face {
  font-family: HKGrotesk;
  font-weight: 500;
  src: url('~@/assets/fonts/hk-grotesk.medium.ttf') format('truetype');
}
@font-face {
  font-family: HKGrotesk;
  font-weight: 600;
  src: url('~@/assets/fonts/hk-grotesk.semibold.ttf') format('truetype');
}
@font-face {
  font-family: HKGrotesk;
  font-weight: 700;
  src: url('~@/assets/fonts/hk-grotesk.bold.ttf') format('truetype');
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
    -moz-appearance:textfield;
}
.v-data-table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}
* {
  font-family: HKGrotesk;
}
.vw-85 {
  width: 85vw;
}
#vuePdfApp {
  height: 100vh;
}
.cursor-pointer {
  cursor: pointer;
}
.v-data-table.list thead th:first-of-type {
  min-width: 105px !important;
}
</style>
