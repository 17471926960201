/* eslint-disable camelcase */
import { getField } from 'vuex-map-fields';

export default {
  getField,
  attachmentsList: ({ attachmentsList }) => attachmentsList,
  loadOrdersList: ({ loadOrdersList }) => loadOrdersList,
  loadOrdersTotal: ({ loadOrdersTotal }) => loadOrdersTotal,
  parkList: ({ parkList }) => parkList,
  parkTotal: ({ parkTotal }) => parkTotal,
  teamsList: (
    { teamsList },
    { filterUEquipaNome, filterUEquipaTarefa, filterBilled },
  ) => teamsList.reduce((
    previous, current, index,
  ) => {
    const prev = previous;

    const {
      u_equipa1,
      u_equipa2,
      u_equipa3,
      u_prc1,
      u_prc2,
      u_prc3,
      u_tarefa1,
      u_tarefa2,
      u_tarefa3,
      u_ftequipa,
      u_ftequip2,
      u_ftequip3,
      u_quant2,
      bostamp,
    } = current;

    if (
      (
        u_equipa1
        && (
          (!filterUEquipaNome && filterUEquipaTarefa === u_tarefa1)
          || (!filterUEquipaTarefa && filterUEquipaNome === u_equipa1)
        )
      )
      || (u_equipa1 && !filterUEquipaNome && !filterUEquipaTarefa)
      || (filterUEquipaNome === u_equipa1 && filterUEquipaTarefa === u_tarefa1)
    ) {
      prev.push({
        ...current,
        u_equipa: u_equipa1,
        u_tarefa: u_tarefa1,
        u_prc: u_prc1,
        u_ftequip: u_ftequipa,
        total: u_quant2 * u_prc1,
        list: 1,
        index,
        key: `t1${bostamp}`,
      });
    }

    if (
      (
        u_equipa2
        && (
          (!filterUEquipaNome && filterUEquipaTarefa === u_tarefa2)
          || (!filterUEquipaTarefa && filterUEquipaNome === u_equipa2)
        )
      )
      || (u_equipa2 && !filterUEquipaNome && !filterUEquipaTarefa)
      || (filterUEquipaNome === u_equipa2 && filterUEquipaTarefa === u_tarefa2)
    ) {
      prev.push({
        ...current,
        u_equipa: u_equipa2,
        u_tarefa: u_tarefa2,
        u_prc: u_prc2,
        u_ftequip: u_ftequip2,
        total: u_quant2 * u_prc2,
        list: 2,
        index,
        key: `t2${bostamp}`,
      });
    }

    if (
      (
        u_equipa3
        && (
          (!filterUEquipaNome && filterUEquipaTarefa === u_tarefa3)
          || (!filterUEquipaTarefa && filterUEquipaNome === u_equipa3)
        )
      )
      || (u_equipa3 && !filterUEquipaNome && !filterUEquipaTarefa)
      || (filterUEquipaNome === u_equipa3 && filterUEquipaTarefa === u_tarefa3)
    ) {
      prev.push({
        ...current,
        u_equipa: u_equipa3,
        u_tarefa: u_tarefa3,
        u_prc: u_prc3,
        u_ftequip: u_ftequip3,
        total: u_quant2 * u_prc3,
        list: 3,
        index,
        key: `t3${bostamp}`,
      });
    }

    return prev;
  }, []).filter(
    ({ u_ftequip }) => (filterBilled === 'all')
      || (filterBilled === 'billed' && u_ftequip !== '')
      || (filterBilled === 'noBill' && u_ftequip === ''),
  ),
  teamsTotal: ({ teamsTotal }) => teamsTotal,
  providerList: ({ providerList }) => providerList,
  providerTotal: ({ providerTotal }) => providerTotal,
  agentList: ({ agentList }) => agentList,
  agentTotal: ({ agentTotal }) => agentTotal,
  transporterList: ({ transporterList }) => transporterList,
  transporterTotal: ({ transporterTotal }) => transporterTotal,
  clientList: ({ clientList }) => clientList,
  clientTotal: ({ clientTotal }) => clientTotal,
  transporters: ({ transporters }) => transporters,
  units: ({ units }) => units,
  attachments: ({ attachments }) => attachments,
  cargoTypes: ({ cargoTypes }) => cargoTypes,
  providers: ({ providers }) => providers,
  teams: ({ teams }) => teams,
  woods: ({ woods }) => woods,
  tasks: ({ tasks }) => tasks,
  plates: ({ plates }) => plates,
  platesAll: ({ platesAll }) => platesAll,
  trailers: ({ trailers }) => trailers,
  uploadAddresses: ({ uploadAddresses }) => uploadAddresses,
  downloadAddresses: ({ downloadAddresses }) => downloadAddresses,
  loadOrder: ({ loadOrder }) => loadOrder,
  loadOrdersFilter: ({ filters }) => filters.loadOrders,
  loadOrderErrors: ({ errors }) => errors.loadOrder,
  attachmentsErrors: ({ errors }) => errors.attachments,
  deliveryNoteErrors: ({ errors }) => errors.deliveryNote,
  loadOrdersFilterErrors: ({ errors }) => errors.loadOrdersFilter,
  deliveryNoteLines: ({ deliveryNoteLines }) => deliveryNoteLines,
  woodProvider: ({ woodProvider }) => woodProvider,
  woodAgent: ({ woodAgent }) => woodAgent,
  transporterPlate: ({ transporterPlate }) => transporterPlate,
  formPrices: ({ formPrices }) => formPrices,
  sumClientTotalFinal: ({ clientList }, { filteredStamps }) => clientList.reduce(
    (previous, current) => (
      filteredStamps.includes(current.bostamp) ? previous : previous + current.total_final
    ), 0,
  ),
  sumTransporterTotal: ({ transporterList }, { filteredStamps }) => transporterList.reduce(
    (previous, current) => (
      filteredStamps.includes(current.bostamp) ? previous : previous + current.total
    ), 0,
  ),
  sumTransporterTotalVat: ({ transporterList }, { filteredStamps }) => transporterList.reduce(
    (previous, current) => (
      filteredStamps.includes(current.bostamp) ? previous : previous + current.total * 0.23
    ), 0,
  ),
  sumTransporterTotalWVat: ({ transporterList }, { filteredStamps }) => transporterList.reduce(
    (previous, current) => (
      filteredStamps.includes(current.bostamp) ? previous : previous + current.total * 1.23
    ), 0,
  ),
  sumProviderTotal: ({ providerList }, { filteredStamps }) => providerList.reduce(
    (previous, current) => (
      filteredStamps.includes(current.bostamp) ? previous : previous + current.total
    ), 0,
  ),
  sumAgentTotal: ({ agentList }, { filteredStamps }) => agentList.reduce(
    (previous, current) => (
      filteredStamps.includes(current.bostamp)
        ? previous
        : previous + (current.u_kickback * current.u_quant2)
    ), 0,
  ),
  sumParkLucro: ({ parkList }, { filteredStamps }) => parkList.reduce(
    (previous, current) => (
      filteredStamps.includes(current.bostamp) ? previous : previous + current.lucro
    ), 0,
  ),
  sumLoadOrdersLucro: ({ loadOrdersList }, { filteredStamps }) => loadOrdersList.reduce(
    (previous, current) => (
      filteredStamps.includes(current.bostamp) ? previous : previous + current.lucro
    ), 0,
  ),
  sumLoadOrdersBonus: ({ loadOrdersList }, { filteredStamps }) => loadOrdersList.reduce(
    (previous, current) => (
      filteredStamps.includes(current.bostamp)
        ? previous
        : previous + (current.u_bonus * current.u_quant2)
    ), 0,
  ),
  sumTeamsTotal: (_, { teamsList, filteredStamps }) => teamsList.reduce(
    (previous, current) => (
      filteredStamps.includes(current.key) ? previous : previous + current.total
    ), 0,
  ),
  sumUPrclTotal: ({ clientList }, { filteredStamps }) => clientList.reduce(
    (previous, current) => (
      filteredStamps.includes(current.bostamp) ? previous : previous + current.u_prcl_total
    ), 0,
  ),
  sumUFttransTotal: ({ clientList }, { filteredStamps }) => clientList.reduce(
    (previous, current) => (
      filteredStamps.includes(current.bostamp) ? previous : previous + current.u_fttrans_total
    ), 0,
  ),
  sumTeamsQuant: (_, { teamsList, filteredStamps }) => teamsList.reduce(
    (previous, { unidade, u_quant2, key }) => {
      const prev = previous;

      if (!filteredStamps.includes(key)) {
        switch (unidade) {
          case 'TON':
            prev[1].val += u_quant2;
            break;
          case 'M3':
            prev[2].val += u_quant2;
            break;
          default: // 'XXX'
            prev[0].val += u_quant2;
        }
      }

      return prev;
    }, [{ unidade: 'XXX', val: 0 }, { unidade: 'TON', val: 0 }, { unidade: 'M3', val: 0 }],
  ),
  sumProviderQuant: ({ providerList }, { filteredStamps }) => providerList.reduce(
    (previous, { unidade, u_quant2, bostamp }) => {
      const prev = previous;

      if (!filteredStamps.includes(bostamp)) {
        switch (unidade) {
          case 'TON':
            prev[1].val += u_quant2;
            break;
          case 'M3':
            prev[2].val += u_quant2;
            break;
          default: // 'XXX'
            prev[0].val += u_quant2;
        }
      }

      return prev;
    }, [{ unidade: 'XXX', val: 0 }, { unidade: 'TON', val: 0 }, { unidade: 'M3', val: 0 }],
  ),
  sumAgentQuant: ({ agentList }, { filteredStamps }) => agentList.reduce(
    (previous, { unidade, u_quant2, bostamp }) => {
      const prev = previous;

      if (!filteredStamps.includes(bostamp)) {
        switch (unidade) {
          case 'TON':
            prev[1].val += u_quant2;
            break;
          case 'M3':
            prev[2].val += u_quant2;
            break;
          default: // 'XXX'
            prev[0].val += u_quant2;
        }
      }

      return prev;
    }, [{ unidade: 'XXX', val: 0 }, { unidade: 'TON', val: 0 }, { unidade: 'M3', val: 0 }],
  ),
  sumLoadOrdersQuant: ({ loadOrdersList }, { filteredStamps }) => loadOrdersList.reduce(
    (previous, { unidade, u_quant2, bostamp }) => {
      const prev = previous;

      if (!filteredStamps.includes(bostamp)) {
        switch (unidade) {
          case 'TON':
            prev[1].val += u_quant2;
            break;
          case 'M3':
            prev[2].val += u_quant2;
            break;
          default: // 'XXX'
            prev[0].val += u_quant2;
        }
      }

      return prev;
    }, [{ unidade: 'XXX', val: 0 }, { unidade: 'TON', val: 0 }, { unidade: 'M3', val: 0 }],
  ),
  sumClientQuant: ({ clientList }, { filteredStamps }) => clientList.reduce(
    (previous, { unidade, u_quant2, bostamp }) => {
      const prev = previous;

      if (!filteredStamps.includes(bostamp)) {
        switch (unidade) {
          case 'TON':
            prev[1].val += u_quant2;
            break;
          case 'M3':
            prev[2].val += u_quant2;
            break;
          default: // 'XXX'
            prev[0].val += u_quant2;
        }
      }

      return prev;
    }, [{ unidade: 'XXX', val: 0 }, { unidade: 'TON', val: 0 }, { unidade: 'M3', val: 0 }],
  ),
  sumCertifiedMaterialQuant: (
    { certifiedMaterialList },
    { filteredStamps },
  ) => certifiedMaterialList.reduce(
    (previous, { unidade, u_quant2, bostamp }) => {
      const prev = previous;

      if (!filteredStamps.includes(bostamp)) {
        switch (unidade) {
          case 'TON':
            prev[1].val += u_quant2;
            break;
          case 'M3':
            prev[2].val += u_quant2;
            break;
          default: // 'XXX'
            prev[0].val += u_quant2;
        }
      }

      return prev;
    }, [{ unidade: 'XXX', val: 0 }, { unidade: 'TON', val: 0 }, { unidade: 'M3', val: 0 }],
  ),
  filterUEquipaNome: ({ filters }) => filters.loadOrders.u_equipa?.nome,
  filterUEquipaEmail: ({ filters }) => filters.loadOrders.u_equipa?.email,
  filterUEquipaTarefa: ({ filters }) => filters.loadOrders.u_tarefa,
  filterUNmfornecNome: ({ filters }) => filters.loadOrders.u_nmfornec?.nome,
  filterUNmfornecEmail: ({ filters }) => filters.loadOrders.u_nmfornec?.email,
  filterUAgentEmail: ({ filters }) => filters.loadOrders.u_agent?.email,
  filterUTranspNome: ({ filters }) => filters.loadOrders.u_transp?.nome,
  filterUTranspEmail: ({ filters }) => filters.loadOrders.u_transp?.email,
  filterClient: ({ filters }) => filters.loadOrders.client?.nome,
  filterClientEmail: ({ filters }) => filters.loadOrders.client?.email,
  filterUMata: ({ filters }) => filters.loadOrders.u_mata,
  filterUAgentNome: ({ filters }) => filters.loadOrders.u_agent?.nome,
  emailDialog: ({ emailDialog }) => emailDialog,
  attachmentsDialog: ({ attachmentsDialog }) => attachmentsDialog,
  createdBostamp: ({ createdBostamp }) => createdBostamp,
  formExpeditionDate: ({ formExpeditionDate }) => formExpeditionDate,
  formExpeditionHour: ({ formExpeditionHour }) => formExpeditionHour,
  filterBilled: ({ filters }) => filters.loadOrders.billed,
  newOrderObrano: ({ newOrderObrano }) => newOrderObrano,
  teamPrices: ({ teamPrices }) => teamPrices,
  filteredStamps: ({ filteredStamps }) => filteredStamps,
  getLoadOrdersListValue: (_, { loadOrdersList }) => (field) => loadOrdersList.reduce(
    (previousValue, currentValue) => (
      previousValue.includes(currentValue[field])
        ? previousValue
        : [...previousValue, currentValue[field]]
    ),
    [],
  ),
  getParkListValue: ({ parkList }) => (field) => parkList.reduce(
    (previousValue, currentValue) => (
      previousValue.includes(currentValue[field])
        ? previousValue
        : [...previousValue, currentValue[field]]
    ),
    [],
  ),
  getCertifiedMaterialListValue: ({ certifiedMaterialList }) => (
    (field) => certifiedMaterialList.reduce(
      (previousValue, currentValue) => (
        previousValue.includes(currentValue[field])
          ? previousValue
          : [...previousValue, currentValue[field]]
      ),
      [],
    )
  ),
  getClientListValue: ({ clientList }) => (field) => clientList.reduce(
    (previousValue, currentValue) => (
      previousValue.includes(currentValue[field])
        ? previousValue
        : [...previousValue, currentValue[field]]
    ),
    [],
  ),
  getTransporterListValue: ({ transporterList }) => (field) => transporterList.reduce(
    (previousValue, currentValue) => (
      previousValue.includes(currentValue[field])
        ? previousValue
        : [...previousValue, currentValue[field]]
    ),
    [],
  ),
  getProviderListValue: ({ providerList }) => (field) => providerList.reduce(
    (previousValue, currentValue) => (
      previousValue.includes(currentValue[field])
        ? previousValue
        : [...previousValue, currentValue[field]]
    ),
    [],
  ),
  getAgentListValue: ({ agentList }) => (field) => agentList.reduce(
    (previousValue, currentValue) => (
      previousValue.includes(currentValue[field])
        ? previousValue
        : [...previousValue, currentValue[field]]
    ),
    [],
  ),
  getTeamsListValue: (_, { teamsList }) => (field) => teamsList.reduce(
    (previousValue, currentValue) => (
      previousValue.includes(currentValue[field])
        ? previousValue
        : [...previousValue, currentValue[field]]
    ),
    [],
  ),
  filterMenu: ({ filterMenu }) => filterMenu,
  globalTitle: ({ filters }) => filters.loadOrders.globalTitle,
  teamsListCountOrder: (
    _,
    { teamsList, filteredStamps },
  ) => [...new Set(teamsList.filter(({ key }) => !filteredStamps.includes(key))
    .map(({ obrano }) => obrano))].length,
};
