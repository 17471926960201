export default {
  setDrawer: (state, payload) => {
    state.drawerOpen = payload;
  },
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setMsg(state, payload) {
    state.msg = payload;
  },
  setAlertType(state, payload) {
    state.alertType = payload;
  },
  setAlert(state, { msg, alertType }) {
    state.msg = msg;
    state.alertType = alertType;
  },
  toggleDrawer: (state) => {
    state.drawerOpen = !state.drawerOpen;
  },
  setChangelogDialog(state, payload) {
    state.changelogDialog = payload;
  },
};
