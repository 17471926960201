import Vue from 'vue';
import { updateField } from 'vuex-map-fields';

export default {
  updateField,
  setLoadOrdersList(state, { data, total }) {
    state.loadOrdersList = data;
    state.loadOrdersTotal = total;
  },
  setParkList(state, { data, total }) {
    state.parkList = data;
    state.parkTotal = total;
  },
  setProviderList(state, { data, total }) {
    state.providerList = data;
    state.providerTotal = total;
  },
  setAgentList(state, { data, total }) {
    state.agentList = data;
    state.agentTotal = total;
  },
  setTeamsList(state, { data }) {
    state.teamsList = data;
  },
  setTeamsTotal(state, payload) {
    state.teamsTotal = payload;
  },
  setTransporterList(state, { data, total }) {
    state.transporterList = data;
    state.transporterTotal = total;
  },
  setClientList(state, { data, total }) {
    state.clientList = data;
    state.clientTotal = total;
  },
  setTransporters(state, payload) {
    state.transporters = payload;
  },
  setCargoTypes(state, payload) {
    state.cargoTypes = payload;
  },
  setUnits(state, payload) {
    state.units = payload;
  },
  setProviders(state, payload) {
    state.providers = payload;
  },
  setTeams(state, payload) {
    state.teams = payload;
  },
  setWoods(state, payload) {
    state.woods = payload;
  },
  setTasks(state, payload) {
    state.tasks = payload;
  },
  setDownloadAddresses(state, payload) {
    state.downloadAddresses = payload;
  },
  setUploadAddresses(state, payload) {
    state.uploadAddresses = payload;
  },
  setPlates(state, payload) {
    state.plates = payload;
  },
  setPlatesAll(state, payload) {
    state.platesAll = payload;
  },
  setTrailers(state, payload) {
    state.trailers = payload;
  },
  setEmailDialog(state, payload) {
    state.emailDialog = payload;
  },
  setAttachmentsDialog(state, payload) {
    state.attachmentsDialog = payload;
  },
  setAttachmentsList(state, payload) {
    state.attachmentsList = payload;
  },
  setLoadOrder(state, payload) {
    state.loadOrder = payload;
  },
  setWoodProvider(state, payload) {
    state.woodProvider = payload;
  },
  setWoodAgent(state, payload) {
    state.woodAgent = payload;
  },
  setTransporterPlate(state, payload) {
    state.transporterPlate = payload;
  },
  setAttachments(state, { data, bostamp }) {
    Vue.set(state.attachments, bostamp, data);
  },
  setLoadOrdersFilter(state, { key, data }) {
    Vue.set(state.filters.loadOrders, key, data);
  },
  setErrors(state, { key, data = [] }) {
    Vue.set(state.errors, key, data);
  },
  setDeliveryNoteLines(state, payload) {
    state.deliveryNoteLines = payload;
  },
  setFormPrices(state, payload) {
    state.formPrices = payload;
  },
  setTeamPrices(state, payload) {
    state.teamPrices = payload;
  },
  setCreatedBostamp(state, payload) {
    state.createdBostamp = payload;
  },
  setFiltersLoadOrdersDates(state, payload) {
    state.filters.loadOrders.startDate = payload;
    state.filters.loadOrders.endDate = payload;
  },
  setFormDateHour(state, { date, hour }) {
    state.formExpeditionDate = date;
    state.formExpeditionHour = hour;
  },
  setFormExpeditionDate(state, payload) {
    state.formExpeditionDate = payload;
  },
  setFormExpeditionHour(state, payload) {
    state.formExpeditionHour = payload;
  },
  setNewOrderObrano(state, payload) {
    state.newOrderObrano = payload;
  },
  setFilteredStamps(state, payload) {
    state.filteredStamps = payload;
  },
};
