import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import pt from 'vuetify/lib/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { 'pt-PT': pt },
    current: process.env.VUE_APP_I18N_LOCALE,
  },
  icons: {
    iconfont: 'mdiSvg',
  },
});
