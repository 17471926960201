import axios from '@/plugins/axios';
import i18n from '@/plugins/i18n';
import dayjs from '@/plugins/dayjs';

export default {
  async fetchServerTime({ commit }) {
    try {
      const response = await axios.get('api/unix-timestamp');

      const stamp = dayjs.unix(response.data);

      const date = stamp.format('YYYY-MM-DD');

      const hour = stamp.add(20, 'minute').format('HH:mm');

      commit('loadOrders/setFiltersLoadOrdersDates', date);

      commit('loadOrders/setFormDateHour', { date, hour });
    } catch (e) {
      const { message } = e.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
};
