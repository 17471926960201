import axios from '@/plugins/axios';
import i18n from '@/plugins/i18n';

export default {
  async showLoadOrder({ commit }, payload) {
    try {
      const response = await axios.get(`api/load-order/${payload}`);

      const { data } = response;

      const {
        // eslint-disable-next-line camelcase
        download_addresses, plates, trailers,
      } = data;

      commit('setDownloadAddresses', download_addresses.map((x) => x.szadrsdesc));
      commit('setPlates', plates.map((x) => x.u_matric));
      commit('setTrailers', trailers.map((x) => x.u_reboq));

      delete data.download_addresses;
      delete data.plates;
      delete data.trailers;

      commit('setLoadOrder', data);
    } catch (e) {
      const { message } = e.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async showAttachments({ commit }, payload) {
    try {
      const params = { bostamp: payload };

      const response = await axios.get('api/load-order-attachments', { params });

      const { data } = response;

      commit('setAttachments', { data, bostamp: payload });
    } catch (e) {
      const { message } = e.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async showAttachmentsList({ commit }, payload) {
    try {
      const response = await axios.get('api/list-attachments', { params: payload });

      const { data } = response;

      commit('setAttachmentsList', data);
    } catch (e) {
      const { message } = e.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async showLoadOrdersList({ commit, getters }, payload) {
    const sortDesc = payload.sortDesc[0] ? 1 : 0;
    const sortBy = payload.sortBy[0];

    const { loadOrdersFilter } = getters;

    const params = {
      ...payload, sortDesc, sortBy, paginate: payload.itemsPerPage, ...loadOrdersFilter,
    };

    try {
      const response = await axios.get('api/lists/load-orders', { params });

      const { data } = response;

      commit('setLoadOrdersList', data);
    } catch (e) {
      const { errors, message } = e.response?.data || {};

      commit('setErrors', { key: 'loadOrdersFilter', data: errors });

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async showCertifiedMaterialList({ commit, getters }, payload) {
    const sortDesc = payload.sortDesc[0] ? 1 : 0;
    const sortBy = payload.sortBy[0];

    const { loadOrdersFilter } = getters;

    const params = {
      ...payload, sortDesc, sortBy, paginate: payload.itemsPerPage, ...loadOrdersFilter,
    };

    try {
      const response = await axios.get('api/lists/load-orders/certified-material', { params });

      const { data } = response;

      commit('updateField', { path: 'certifiedMaterialList', value: data.data });
      commit('updateField', { path: 'certifiedMaterialTotal', value: data.total });
    } catch (e) {
      const { errors, message } = e.response?.data || {};

      commit('setErrors', { key: 'loadOrdersFilter', data: errors });

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async showParkList({ commit, getters }, payload) {
    const sortDesc = payload.sortDesc[0] ? 1 : 0;
    const sortBy = payload.sortBy[0];

    const { loadOrdersFilter } = getters;

    const params = {
      ...payload, sortDesc, sortBy, paginate: payload.itemsPerPage, ...loadOrdersFilter,
    };

    try {
      const response = await axios.get('api/lists/load-orders/park', { params });

      const { data } = response;

      commit('setParkList', data);
    } catch (e) {
      const { errors, message } = e.response?.data || {};

      commit('setErrors', { key: 'loadOrdersFilter', data: errors });

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async showProviderList({ commit, getters }, payload) {
    const sortDesc = payload.sortDesc[0] ? 1 : 0;
    const sortBy = payload.sortBy[0];

    const { loadOrdersFilter, filterUNmfornecNome, filterUMata } = getters;

    const params = {
      ...payload,
      sortDesc,
      sortBy,
      paginate: payload.itemsPerPage,
      ...loadOrdersFilter,
      u_nmfornec: filterUNmfornecNome,
      u_mata: filterUMata,
    };

    try {
      const response = await axios.get('api/lists/load-orders/provider', { params });

      const { data } = response;

      commit('setProviderList', data);
    } catch (e) {
      const { errors, message } = e.response?.data || {};

      commit('setErrors', { key: 'loadOrdersFilter', data: errors });

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async showAgentList({ commit, getters }, payload) {
    const sortDesc = payload.sortDesc[0] ? 1 : 0;
    const sortBy = payload.sortBy[0];

    const { loadOrdersFilter, filterUMata, filterUAgentNome } = getters;

    const params = {
      ...payload,
      sortDesc,
      sortBy,
      paginate: payload.itemsPerPage,
      ...loadOrdersFilter,
      u_agent: filterUAgentNome,
      u_mata: filterUMata,
    };

    try {
      const response = await axios.get('api/lists/load-orders/agent', { params });

      const { data } = response;

      commit('setAgentList', data);
    } catch (e) {
      const { errors, message } = e.response?.data || {};

      commit('setErrors', { key: 'loadOrdersFilter', data: errors });

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async showClientList({ commit, getters }, payload) {
    const sortDesc = payload.sortDesc[0] ? 1 : 0;
    const sortBy = payload.sortBy[0];

    const { loadOrdersFilter, filterClient } = getters;

    const params = {
      ...payload,
      sortDesc,
      sortBy,
      paginate: payload.itemsPerPage,
      ...loadOrdersFilter,
      client: filterClient,
    };

    try {
      const response = await axios.get('api/lists/load-orders/client', { params });

      const { data } = response;

      commit('setClientList', data);
    } catch (e) {
      const { errors, message } = e.response?.data || {};

      commit('setErrors', { key: 'loadOrdersFilter', data: errors });

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async showTeamsList({ commit, getters }, payload) {
    const sortDesc = payload.sortDesc[0] ? 1 : 0;
    const sortBy = payload.sortBy[0];

    const { loadOrdersFilter, filterUEquipaNome } = getters;

    const params = {
      ...payload,
      sortDesc,
      sortBy,
      paginate: payload.itemsPerPage,
      ...loadOrdersFilter,
      u_equipa: filterUEquipaNome,
    };

    try {
      const response = await axios.get('api/lists/load-orders/teams', { params });

      commit('setTeamsList', response.data);

      const response2 = await axios.get('api/count-teams-total', { params });

      commit('setTeamsTotal', response2.data);
    } catch (e) {
      const { errors, message } = e.response?.data || {};

      commit('setErrors', { key: 'loadOrdersFilter', data: errors });

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async showTransporterList({ commit, getters }, payload) {
    const sortDesc = payload.sortDesc[0] ? 1 : 0;
    const sortBy = payload.sortBy[0];

    const { loadOrdersFilter, filterUTranspNome } = getters;

    const params = {
      ...payload,
      sortDesc,
      sortBy,
      paginate: payload.itemsPerPage,
      ...loadOrdersFilter,
      u_transp: filterUTranspNome,
    };

    try {
      const response = await axios.get('api/lists/load-orders/transporter', { params });

      const { data } = response;

      commit('setTransporterList', data);
    } catch (e) {
      const { errors, message } = e.response?.data || {};

      commit('setErrors', { key: 'loadOrdersFilter', data: errors });

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async showTransporters({ commit }) {
    try {
      const response = await axios.get('api/transporters');

      const { data } = response;

      commit('setTransporters', data);
    } catch (e) {
      const { message } = e.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async showCargoTypes({ commit }) {
    try {
      const response = await axios.get('api/cargoTypes');

      const { data } = response;

      commit('setCargoTypes', data);
    } catch (e) {
      const { message } = e.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async showUnits({ commit }) {
    try {
      const response = await axios.get('api/units');

      const { data } = response;

      commit('setUnits', data);
    } catch (e) {
      const { message } = e.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async showProviders({ commit }) {
    try {
      const response = await axios.get('api/providers');

      const { data } = response;

      commit('setProviders', data);
    } catch (e) {
      const { message } = e.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async showTeams({ commit }) {
    try {
      const response = await axios.get('api/teams');

      const { data } = response;

      commit('setTeams', data);
    } catch (e) {
      const { message } = e.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async showWoods({ commit }, payload = {}) {
    try {
      const response = await axios.get('api/woods', { params: payload });

      const { data } = response;

      commit('setWoods', data);
    } catch (e) {
      const { message } = e.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async showCertifiedWoods({ commit }) {
    try {
      const params = { u_mfsc100: true };

      const response = await axios.get('api/woods', { params });

      const { data } = response;

      commit('updateField', { path: 'certifiedWoods', value: data });
    } catch (e) {
      const { message } = e.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async showNewOrderObrano({ commit }) {
    try {
      const response = await axios.get('api/new-load-order-obrano');

      const { data } = response;

      commit('setNewOrderObrano', data);
    } catch (e) {
      const { message } = e.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async showTasks({ commit }) {
    try {
      const response = await axios.get('api/tasks');

      const { data } = response;

      commit('setTasks', data);
    } catch (e) {
      const { message } = e.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async showDownloadAddresses({ commit }, payload) {
    try {
      const params = { clstamp: payload };

      const response = await axios.get('api/download-addresses', { params });

      const { data } = response;

      commit('setDownloadAddresses', data);
    } catch (e) {
      const { message } = e.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async showPlates({ commit }, payload) {
    try {
      const params = { u_transp: payload };

      const response = await axios.get('api/plates', { params });

      const { data } = response;

      commit('setPlates', data);
    } catch (e) {
      const { message } = e.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async showPlatesAll({ commit }) {
    try {
      const response = await axios.get('api/plates-all');

      const { data } = response;

      commit('setPlatesAll', data);
    } catch (e) {
      const { message } = e.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async showTrailers({ commit }, payload) {
    try {
      const params = { u_transp: payload };

      const response = await axios.get('api/trailers', { params });

      const { data } = response;

      commit('setTrailers', data);
    } catch (e) {
      const { message } = e.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async showWoodProvider({ commit }, payload) {
    try {
      const params = { wood: payload };

      const response = await axios.get('api/wood-provider', { params });

      const { data } = response;

      commit('setWoodProvider', data);
    } catch (e) {
      const { message } = e.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async showWoodAgent({ commit }, payload) {
    try {
      const params = { wood: payload };

      const response = await axios.get('api/wood-agent', { params });

      const { data } = response;

      const { nome, email } = data;

      commit('setWoodAgent', { nome, email });
    } catch (e) {
      const { message } = e.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async showTransporterPlate({ commit }, payload) {
    try {
      const params = { plate: payload };

      const response = await axios.get('api/transporter-plate', { params });

      const { data } = response;

      commit('setTransporterPlate', data);
    } catch (e) {
      const { message } = e.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async showUploadAddresses({ commit }) {
    try {
      const response = await axios.get('api/upload-addresses');

      const { data } = response;

      commit('setUploadAddresses', data);
    } catch (e) {
      const { message } = e.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async createLoadOrder({ commit }, payload) {
    try {
      const response = await axios.post('api/load-order', payload);

      const { data } = response;

      commit('setCreatedBostamp', data);

      commit('setAlert', { msg: i18n.t('wot.success'), alertType: 'success' }, { root: true });
    } catch (e) {
      const { errors, message } = e.response?.data || {};

      commit('setErrors', { key: 'loadOrder', data: errors });

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });

      throw e;
    }
  },
  async editLoadOrder({ commit }, payload) {
    try {
      await axios.put('api/load-order', payload);

      commit('setAlert', { msg: i18n.t('wot.success'), alertType: 'success' }, { root: true });
    } catch (e) {
      const { errors, message } = e.response?.data || {};

      commit('setErrors', { key: 'loadOrder', data: errors });

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async updateOrdersListValue({ commit }, payload) {
    try {
      const {
        com = true,
        persist = true,
        type = '',
        index,
        field,
        value,
      } = payload;

      if (persist) {
        await axios.patch('api/load-order', payload);
      }

      if (com) {
        let path;

        switch (type) {
          case 'certifiedMaterial':
            path = `certifiedMaterialList[${[index]}].${field}`;
            break;
          case 'transporter':
            path = `transporterList[${[index]}].${field}`;
            break;
          case 'provider':
            path = `providerList[${[index]}].${field}`;
            break;
          case 'teams':
            path = `teamsList[${[index]}].${field}`;
            break;
          case 'park':
            path = `parkList[${[index]}].${field}`;
            break;
          case 'agent':
            path = `agentList[${[index]}].${field}`;
            break;
          case 'client':
            path = `clientList[${[index]}].${field}`;
            break;
          default:
            path = `loadOrdersList[${[index]}].${field}`;
            break;
        }

        commit('updateField', { path, value });
      }

      commit('setAlert', { msg: i18n.t('wot.success'), alertType: 'success' }, { root: true });
    } catch (e) {
      const { message } = e.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async showDeliveryNoteLines({ commit }, payload) {
    try {
      const params = { bostamp: payload };

      const response = await axios.get('api/delivery-note-lines', { params });

      const { data } = response;

      commit('setDeliveryNoteLines', data);
    } catch (e) {
      const { message } = e.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async showMaxNomquinaClstamp({ commit }, payload) {
    try {
      const params = { clstamp: payload };

      const response = await axios.get('api/max-nomquina', { params });

      const { data } = response;

      commit('updateField', { path: 'nomquina', value: data });
    } catch (e) {
      const { message } = e.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async createDeliveryNote({ commit }, payload) {
    try {
      await axios.post('api/delivery-note', payload);

      commit('setAlert', { msg: i18n.t('wot.success'), alertType: 'success' }, { root: true });
    } catch (e) {
      const { errors, message } = e.response?.data || {};

      commit('setErrors', { key: 'deliveryNote', data: errors });

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async destroyAttachment({ commit }, payload) {
    try {
      await axios.delete(`api/attachment/${payload}`);

      commit('setAlert', { msg: i18n.t('wot.success'), alertType: 'success' }, { root: true });
    } catch (e) {
      const { message } = e.response.data;

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async createAttachment({ commit }, payload) {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };

      const body = new FormData();

      Object.keys(payload).forEach((key) => {
        body.append(key, payload[key]);
      });

      await axios.post('api/attachment', body, { headers });

      commit('setAttachmentsDialog', false);

      commit('setAlert', { msg: i18n.t('wot.success'), alertType: 'success' }, { root: true });
    } catch (e) {
      const { errors, message } = e.response?.data || {};

      commit('setErrors', { key: 'attachments', data: errors });

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async calculateFormPrices({ commit }, payload) {
    try {
      const response = await axios.get('api/calculate-form-prices', { params: payload });

      const { data } = response;

      commit('setFormPrices', data);
    } catch (e) {
      const { errors, message } = e.response?.data || {};

      commit('setErrors', { key: 'loadOrder', data: errors });

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });

      throw e;
    }
  },
  async sendEmails({ commit }, payload) {
    try {
      await axios.post('api/send-emails', payload);

      commit('setEmailDialog', false);

      commit('setAlert', { msg: i18n.t('wot.success'), alertType: 'success' }, { root: true });
    } catch (e) {
      const { message } = e.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async sendListEmail({ commit, getters }, payload) {
    try {
      const sortDesc = payload.sortDesc[0] ? 1 : 0;
      const sortBy = payload.sortBy[0];

      const {
        loadOrdersFilter,
        filterUEquipaNome,
        filterUNmfornecNome,
        filterUTranspNome,
        filterUAgentNome,
        filterMenu,
        filterClient,
      } = getters;

      const body = {
        ...payload,
        sortDesc,
        sortBy,
        paginate: payload.itemsPerPage,
        ...loadOrdersFilter,
        u_nmfornec: filterUNmfornecNome,
        u_equipa: filterUEquipaNome,
        u_transp: filterUTranspNome,
        u_agent: filterUAgentNome,
        filter: filterMenu,
        client: filterClient,
      };

      await axios.post('api/send-list-email', body);

      commit('setEmailDialog', false);

      commit('setAlert', { msg: i18n.t('wot.success'), alertType: 'success' }, { root: true });
    } catch (e) {
      const { message } = e.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async downloadPdf({ commit, getters }, payload) {
    try {
      const sortDesc = payload.sortDesc[0] ? 1 : 0;
      const sortBy = payload.sortBy[0];

      const {
        loadOrdersFilter,
        filterUEquipaNome,
        filterUNmfornecNome,
        filterUTranspNome,
        filterMenu,
        filterUAgentNome,
        filterClient,
        globalTitle,
      } = getters;

      const body = {
        ...payload,
        sortDesc,
        sortBy,
        paginate: payload.itemsPerPage,
        ...loadOrdersFilter,
        u_nmfornec: filterUNmfornecNome,
        u_equipa: filterUEquipaNome,
        u_transp: filterUTranspNome,
        filter: filterMenu,
        u_agent: filterUAgentNome,
        client: filterClient,
        globalTitle,
      };

      const headers = {
        Accept: 'application/pdf',
      };

      const response = await axios.post('api/download-pdf', body, { headers, responseType: 'blob' });

      const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));

      const link = document.createElement('a');

      link.href = url;

      link.download = i18n.t('wot.listFilename');

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    } catch (e) {
      const { message } = e.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
};
