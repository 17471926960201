import getters from './getters';
import mutations from './mutations';
import actions from './actions';

export default {
  namespaced: true,
  state: () => ({
    nomquina: null,
    attachmentsList: [],
    createdBostamp: '',
    attachmentsDialog: false,
    emailDialog: false,
    woodProvider: '',
    woodAgent: '',
    transporterPlate: '',
    certifiedMaterialList: [],
    certifiedMaterialTotal: 0,
    loadOrdersList: [],
    loadOrdersTotal: 0,
    parkList: [],
    parkTotal: 0,
    providerList: [],
    providerTotal: 0,
    teamsList: [],
    teamsTotal: 0,
    transporterList: [],
    transporterTotal: 0,
    agentList: [],
    agentTotal: 0,
    clientList: [],
    clientTotal: 0,
    transporters: [],
    cargoTypes: [],
    units: [],
    plates: [],
    platesAll: [],
    trailers: [],
    providers: [],
    teams: [],
    woods: [],
    certifiedWoods: [],
    tasks: [],
    uploadAddresses: [],
    downloadAddresses: [],
    attachments: {},
    loadOrder: {},
    deliveryNoteLines: [],
    formPrices: {},
    teamPrices: [],
    formExpeditionDate: null,
    formExpeditionHour: null,
    newOrderObrano: null,
    filters: {
      loadOrders: {
        startDate: null,
        endDate: null,
        billed: 'noBill',
        u_transp: null,
        u_nmfornec: null,
        u_equipa: null,
        u_tarefa: null,
        client: null,
        u_mata: null,
        u_agent: null,
        globalTitle: '',
        hasTeams: 'all',
      },
    },
    filterMenu: {
      u_transp: [],
      nome: [],
      u_tipos: [],
      u_carga: [],
      u_carga2: [],
      u_descarga: [],
      u_descarg2: [],
      u_quant2: [],
      u_fttransp: [],
      nft: [],
      nft1: [],
      carga: [],
      descarga: [],
      u_equipa1: [],
      u_tarefa1: [],
      u_prc1: [],
      u_equipa2: [],
      u_tarefa2: [],
      u_prc2: [],
      u_equipa3: [],
      u_tarefa3: [],
      u_prc3: [],
      u_mata: [],
      u_tarefa: [],
      u_prc: [],
      u_agent: [],
      u_kickback: [],
      unidade: [],
      u_mataalt: [],
      lobs: [],
    },
    filteredStamps: [],
    errors: {
      loadOrder: [],
      loadOrdersFilter: [],
      deliveryNote: [],
      attachments: [],
    },
  }),
  getters,
  mutations,
  actions,
};
